import * as React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import Logo from '../assets/kematixDigitalLogo.png'

const HomepageLink = styled(Link)`
  font-size: 1.5rem;
  font-weight: 600;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`
const NavbarFixed = styled(Navbar)`
  background-color: #1b1d2a;
  z-index: 7657567567657;
  min-width: 100%;
  position: fixed;
  top: 0;
  .dropdown-item {
    padding: 0px !important;
  }
  .dropdown-menu {
    background-color: #1b1d2a !important;
  }
  justify-content: space-around;
`

const NavDropdownLinks = styled(NavDropdown)`
  a:nth-of-type(-n + 5) {
    padding-left: 7px;
  }
  .dropdown-menu {
    background-color: gray !important;
    border-radius: 0px;
  }
`

const MinWidthNavBar = styled.div`
font-family: Raleway;
max-width: 1200px;
@media only screen and (max-width: 1200px) {
 .navbar-brand {
  margin-right: auto;
  margin-left: auto;
 }
 display: flex;
 flex-direction: column;
}
}

@media only screen and (min-width: 1200px) {

  width: 85%;
  display: flex;
  flex-direction: row;
 }`

const EstimatedProjectButton = styled(Link)`
  padding-top: 0.35rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 11px;
  border-radius: 28px;
  height: 40px;
  margin-left: 25px;
  border: 2px solid rgb(241, 182, 49);
  transition: 0.3s;
  color: #1b1d2a;
  background-color: rgb(241, 182, 49);
  text-decoration: none;
  font-weight: 750;
`
const DirectoryLink = styled(Link)`
  color: white;
  :hover {
    color: white;
    text-decoration: none;
  }
`
interface HeaderProps {
  title: string
}

const Header: React.FC<HeaderProps> = () => (
  <NavbarFixed collapseOnSelect expand="xl" variant="dark">
    <MinWidthNavBar>
      <Navbar.Brand style={{ margin: 'auto' }} href="/">
        <Link to="/">
          {' '}
          <img alt="Kematix Digital Logo Icon on Header" src={Logo} width="164px" />
        </Link>
      </Navbar.Brand>
      {/* list */}
      <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ border: 'none' }} />
      <Navbar.Collapse id="basic-navbar-nav" style={{ border: 'none' }}>
        <Nav className="ml-auto">
          <DirectoryLink to="/" style={{ color: '#FFFFFF', padding: '20px', fontWeight: '100' }}>
            Home
          </DirectoryLink>
          <DirectoryLink to="/#kematix-services" style={{ color: '#FFFFFF', padding: '20px', fontWeight: '100' }}>
            Services
          </DirectoryLink>
          <DirectoryLink to="/software-development-portfolio" style={{ color: '#FFFFFF', padding: '20px', fontWeight: '100' }}>
            Portfolio
          </DirectoryLink>
          <EstimatedProjectButton to="/contact-us">Contact Us</EstimatedProjectButton>
        </Nav>
      </Navbar.Collapse>
    </MinWidthNavBar>
  </NavbarFixed>
)

export default Header
