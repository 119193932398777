import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import 'modern-normalize'
import '../styles/normalize'

import Header from '../components/Header'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import bingo1 from '../assets/apple-touch-icon.png'
import bingo2 from '../assets/favicon-32x32.png'
import bingo3 from '../assets/favicon-16x16.png'
import bingo5 from '../assets/safari-pinned-tab.svg'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const IndexLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <LayoutRoot>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
            { name: 'google-site-verification', content: 'YGTsj9--5rTcKsc6zqxLF6gm25wndxjuy0-A0VeM2C0' }
          ]}
        >
          <link rel="apple-touch-icon" sizes="180x180" href={bingo1} />
          <link rel="icon" type="image/png" sizes="32x32" href={bingo2} />
          <link rel="icon" type="image/png" sizes="16x16" href={bingo3} />
          <link rel="mask-icon" href={bingo5} color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <Header title={data.site.siteMetadata.title} />
        <LayoutMain>{children}</LayoutMain>
      </LayoutRoot>
    )}
  />
)

export default IndexLayout
