import * as React from 'react'
import styled from '@emotion/styled'

import { widths } from '../styles/variables'
import { getEmSize } from '../styles/mixins'

const StyledContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: 83vw;

  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #ced8de;
  max-width: 1216px;
  pre {
    background-color: #12141c;
    border: 1px solid #999;
    display: block;
    padding: 20px;
    margin-left: 61px;
    margin-right: 61px;
    color: #ced8de;
  }
  pre code {
    order: 1px solid var(--border-color);
    font-size: 0.85em;
    white-space: pre-wrap;
    padding: 0.2em 0.5em;
  }
`

interface ContainerProps {
  className?: string
}

const Container: React.FC<ContainerProps> = ({ children, className }) => <StyledContainer className={className}>{children}</StyledContainer>

export default Container
// h1 {
//   width: 100%;
//   position: relative;
//   font-weight: 800;
//   margin: 3rem 0px 20px;
//   color: #ced8de;
// }
// div {
//   background-color: #1a1c28;
//   padding-top: 45px;
// }
// ol {
//   padding-left: 76px;
//   padding-right: 65px;
//   padding-top: 20px;
// }
// p {
//   padding-left: 61px;
//   padding-right: 54px;
// }
// li {
//   @media only screen and (max-width: 281px) {
//     font-size: 11px;
//   }
// }
// h3 {
//   width: 100%;
//   position: relative;
//   font-weight: 800;
//   margin: 1rem 0px 20px;
//   color: #ced8de;
// }
